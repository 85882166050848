


















































































































































































.toolbar::v-deep {
  height: auto !important;

  .v-toolbar__content {
    height: auto !important;
  }
}

.col-update {
  justify-content: center;
  align-items: center;
  display: flex;
}
